<template>
    <div v-if="linkPdf != false">
        <div id="heade-action" class="w-100 bg-white sticky-top p-3" style="align-items:center;justify-content:center;">
            <div>
                <img :src="$api.ste.logo" class="logo"/>
            </div>
            <div class="ml-auto">
                <b-button-group size="sm">                     
                    <b-button class="ml-1" :href="linkPdf" target="_blank">
                        <i class="fas fa-download"></i><br/>
                        <span class="h6">Telecharger</span>
                    </b-button>                    
                </b-button-group>                
            </div>
            <div class="ml-auto" v-if="user != false">
                <b-button class="ml-2" size="sm" variant="light-info" v-if="signable" @click="sign()">
                    <i class="fas" :class="!sign_load ? 'fa-signature' : 'fa-spin fa-spinner'"></i><br/>
                    <span class="h6">Signature</span>
                </b-button>
                <b-button class="ml-2" size="sm" :variant="call_color" :href="`tel:${user.telephone}`">
                    <i class="fas fa-phone"></i><br/>
                    <span class="h6">Appelez {{ user.prenom }} {{ user.nom[0] }}
                    </span>
                </b-button>
            </div>
        </div>
        <b-container v-if="ready"  class="mt-3" style="margin-bottom:50px">            
            <b-row class="justify-content-center">
                <b-col lg="9" md="11">
                    <pdf ref="myPdfComponent" v-for="i in numPages" :id="`Page-${i}`" :key="i" :src="src" :page="i" style="margin-top: 25px;"></pdf>                        
                </b-col>
            </b-row>
        </b-container>        
        <div v-else class="d-block text-center h3 text-white" style="padding-top: 20%; padding-bottom: 20%;" >
            <div>
                <strong>
                    Merci de patienter, pendant le chargement votre document
                </strong><br><br>
                <i class="fa fa-spin fa-spinner fa-4x text-danger"></i>
            </div>
        </div>   
        <b-modal v-model="showModal" title="Contactez-nous" centered size="lg" header-class="border-bottom-0" hide-footer>
            <p class="text-center lead mb-4">
                Nous sommes là pour répondre à toutes vos questions. Choisissez le moyen de communication qui vous convient le mieux.
            </p>      
            <div class="contact-options">
                <b-card class="contact-option">
                    <b-link :href="`tel:${phone}`">
                        <div class="text-center">
                            <i class="fas fa-phone mb-3"></i>
                            <h4>Téléphone</h4>
                            <p class="mb-0 text-muted">{{ phone }}</p>
                        </div>
                    </b-link>
                </b-card>
                <b-card class="contact-option">
                    <b-link :href="`mailto:${user.email}`">
                        <div class="text-center">
                            <i class="fas fa-envelope mb-3"></i>
                            <h4>Email</h4>
                            <p class="mb-0 text-muted">{{ user.email }}</p>
                        </div>
                    </b-link>
                </b-card>
                <b-card class="contact-option contact-wa" v-if="user.wa != false">
                    <b-link :href="user.wa">
                        <div class="text-center">
                            <i class="fab fa-whatsapp mb-3"></i>
                            <h4>WhatsApp</h4>
                            <p class="mb-0 text-muted">Discuter avec nous</p>
                        </div>
                    </b-link>
                </b-card>
            </div>
        </b-modal>     
        <call-us-selector v-if="user != false && user.pbx_chat != null" ref="pbx" phonesystem-url="https://assureur-francais.3cx.eu" :party="user.pbx_chat"></call-us-selector>
    </div>
</template>
<script>
    //import InputForm from '@/components/InputForm';
    import pdf from 'vue-pdf'    
    export default {
        name: "File",
        components: {
            pdf,
            //InputForm            
        },        
        data() {
            return {
                ready: false,
                showModal: false,
                call_color: 'primary',
                user:false,
                linkPdf: false,
                uuid: null,
                session: null,
                src: null,
			    numPages: undefined,
                anchor: 'Page-1',
                during:[],
                totalD:0,
                message:null, 
                sign_load: false,
                signable: false,
                sign_url: null           
            }
        },
        beforeMount(){
            this.session = this.$api.uuid();
            this.uuid = this.$route.params.uuid;
            this.$api.ajax('/file/view/'+this.uuid, null, res => {
                if(res.status == true){
                    this.user = res.data.user;
                    //this.linkPdf = res.data.link;
                    this.linkPdf = res.data.link;
                    this.signable = res.data.signable;
                    this.sign_url = res.data.sign_url;
                    this.src = pdf.createLoadingTask(this.linkPdf);                    
                    this.src.promise.then(pdf => {
                        this.numPages = pdf.numPages;
                        this.initAnchorDetector();
                        this.initDuring();
                        if(this.user.modal != false){
                            setTimeout(() => {
                                this.showModal = true;
                            }, this.user.modal*1000);
                        }
                        this.ready = true;                        
                    });
                }
            })
        },
        mounted() { 
            setInterval(() => {
                this.call_color = this.call_color === 'primary' ? 'light-danger' : 'primary';
            },2000)                               
        },        
        methods: {
            initAnchorDetector(){
                var the = this;
                document.addEventListener("scroll", () => {
                    for(var i = 0; i < this.numPages; i++){
                        var id = `Page-${i+1}`;
                        var elt = document.getElementById(id);
                        var top = window.pageYOffset;
                        var distance = top - elt.offsetTop;
                        if(distance < 30 && distance > -30){
                            the.anchor = id;
                        }
                    }
                });
            },
            initDuring(){
                var the = this;
                setInterval(() => {
                    var check = the.during.find(d => d.key === this.anchor);
                    if(check === undefined){
                        check = {
                            key: this.anchor,
                            duree:1
                        }
                        the.during.push(check);
                    }else{
                        check.duree += 1
                    }  
                    this.totalD += 1                 
                    if(this.totalD % 5 === 0){
                        the.sendTracking();
                    }
                }, 1000);
            },
            sendTracking(){
                var params = {
                    uuid: this.uuid,
                    session: this.session,
                    durring: this.during,
                    totalD: this.totalD
                }
                var option = {
                    headers:{
                        "Content-Type":"application/json",                        
                    }                    
                }
                option.method = "POST";
                option.body = JSON.stringify(params);
                var url = this.$api.url.api+'/file/tracking';
                return fetch(url, option);
            },
            onOpenChat(){
                var chat = document.getElementById("wplc-chat-button");
                chat.click();
                
            },
            sign(){
                if(this.sign_url === null){
                    if(this.sign_load === true){
                        return false;
                    }
                    this.sign_load = true;
                    this.$api.ajax('/file/sign/'+this.uuid, null, res => {
                        if(res.status === true){
                            this.sign_url = res.data;
                            window.open(this.sign_url, "_blank");
                        }
                        this.sign_load = false;
                    })
                }else{
                    window.open(this.sign_url, "_blank");
                }
            }
        },     
        computed:{
            phone() {      
                const numbers = this.user.telephone.replace(/\D/g, '')      
                if (numbers.length === 10 && numbers.startsWith('0')) {        
                    return `+33 ${numbers.slice(1, 2)} ${numbers.slice(2, 4)} ${numbers.slice(4, 6)} ${numbers.slice(6, 8)} ${numbers.slice(8)}`
                }
                return numbers
            },
        }   
    }        
</script>
<style lang="scss">    
    .logo {
        max-width: 250px;
        
    }        
    #heade-action {
        display: flex;
    }
    @media (max-width: 768px){
        #heade-action {
            display: block;
            text-align: center;
        } 
        #heade-action div {
            margin-bottom: 10px;
        }
        #heade-action .btn {
            padding: 0.50rem 0.70rem;
            font-size: 0.925rem;
            line-height: 1.35;
            border-radius: 0.85rem;
        }                
    }            
    .contact-options {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        gap: 20px;
        margin: 20px 0;
    }
    .contact-option {
        cursor: pointer;
        transition: all 0.3s ease;
        border: 1px solid #dee2e6;
    }
    .contact-option:hover {
        transform: translateY(-5px);
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
        border-color: #007bff;
    }
    .contact-option i {
        font-size: 2rem;
        color: #007bff;
    }
    .contact-option h4 {
        font-size: 1.25rem;
        margin: 10px 0;
    }
    ::v-deep .modal-content {
        border-radius: 8px;
        border: none;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    }
    ::v-deep .modal-header {
        padding: 1.5rem;
    }
    ::v-deep .modal-body {
        padding: 1.5rem;
    }
</style>